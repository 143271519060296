import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import MinimalAgency from '../views/all-home-version/MinimalAgency.vue'
// import MainDemo from '../views/all-home-version/MainDemo.vue'
// import Demo from '../views/Demo.vue'
// import Service from '../views/Service.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: MinimalAgency,
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        }
    },
    // {
    //     path: '/main-demo',
    //     name: 'MainDemo',
    //     meta: {
    //         title: 'Histories Des Sables || Galletas personalizadas con Corazón'
    //     },
    //     component: () =>
    //         import ('../views/all-home-version/MainDemo.vue')
    // },
    {
        path: '/freelancer',
        name: 'Freelancer',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/Freelancer.vue')
    },
    {
        path: '/agency',
        name: 'Agency',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/Agency.vue')
    },
    {
        path: '/minimal-agency',
        name: 'MinimalAgency',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/MinimalAgency.vue')
    },
    {
        path: '/creative-portfolio',
        name: 'CreativePortfolio',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/CreativePortfolio.vue')
    },
    {
        path: '/designer-portfolio',
        name: 'DesignerPortfolio',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },

        component: () =>
            import ('../views/all-home-version/DesignerPortfolio.vue')
    },
    {
        path: '/vertical-portfolio',
        name: 'VerticalPortfolio',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/VerticalPortfolio.vue')
    },
    {
        path: '/multiscroll-portfolio',
        name: 'MultiScrollPortfolio',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/MultiScrollPortfolio.vue')
    },
    {
        path: '/parallax-home',
        name: 'ParallaxHome',
        meta: {
            title: 'Histories Des Sables || Galletas personalizadas con Corazón'
        },
        component: () =>
            import ('../views/all-home-version/ParallaxHome.vue')
    },
    {
        path: '/portfolio-details',
        name: 'PortfolioDetails',
        meta: {
            title: 'Histories Des Sables  || Portfolio Details'
        },
        component: () =>
            import ('../views/PortfolioDetails.vue')
    },
    {
        path: '/service-details',
        name: 'ServiceDetails',
        meta: {
            title: 'Histories Des Sables  || Service Details'
        },
        component: () =>
            import ('../views/ServiceDetails.vue')
    },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        meta: {
            title: 'Histories Des Sables  || Blog Details'
        },
        component: () =>
            import ('../views/BlogDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'Histories Des Sables  || Contact'
        },
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        meta: {
            title: 'Histories Des Sables  || AboutUs'
        },
        component: () =>
            import ('../views/AboutUs.vue')
    },
    {
        path: '/awards',
        name: 'Awards',
        meta: {
            title: 'Histories Des Sables  || Awards'
        },
        component: () =>
            import ('../views/Awards.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            title: 'Histories Des Sables  || Blog'
        },
        component: () =>
            import ('../views/Blog.vue')
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        meta: {
            title: 'Histories Des Sables  || Portfolio'
        },
        component: () =>
            import ('../views/Portfolio.vue')
    },
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: 'Histories Des Sables  || Service'
        },
        component: () =>
            import ('../views/Service.vue')
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'Histories Des Sables  || 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router