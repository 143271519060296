<template>
  <div>
    <v-tabs v-model="tab" centered flat hide-slider color="primary">
      <v-tab :ripple="false" v-for="item in tabItems" :key="item.id">{{
        item.name
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in tabContent"
        :key="item.id"
        class="portfolio-wrapper portfolio-list mt--60"
      >
        <v-row class="row--5" id="animated-thumbnials">
          <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(item, i) in item.content"
            :key="i"
            @click="index = i"
          >
            <div class="portfolio_style--3">
              <div class="thumb">
                <router-link to="/portfolio-details"
                  ><img :src="item.src" alt="personal portfolio"
                /></router-link>
                <div class="port-hover-action">
                  <div class="content">
                    <h3>
                      <router-link to="/portfolio-details">{{
                        item.title
                      }}</router-link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tab: null,
        index: null,
        tabItems: [
          {
            id: 1,
            name: "Todos",
          },
          {
            id: 2,
            name: "Varios",
          },
          {
            id: 3,
            name: "Festivos",
          },
          {
            id: 4,
            name: "Populares",
          },
          {
            id: 5,
            name: "Animales",
          },
          {
            id: 6,
            name: "Disney ",
          },
        ],
        tabContent: [
          {
            id: 1,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/popular/got.jpg"),
                title: `Game of Thrones`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/pandas.jpg"),
                title: `Pandas`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/encanto3.jpg"),
                title: `Encanto`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/festivos/halloween3.jpg"),
                title: `Halloween`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/popular/ansel_gretel.jpg"),
                title: `Ansel y Gretel`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/dinosaurios.jpg"),
                title: `Dinosaurios`,
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/varios/aviones_piloto.jpg"),
                title: `Aviones y pilotos`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/varios/aviones.jpg"),
                title: `Aviones`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/varios/disfraces.jpg"),
                title: `Disfraces`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/varios/fantasmas.jpg"),
                title: `Fantasmas`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/varios/recien_casados.jpg"),
                title: `Recien Casados`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/varios/varios.jpg"),
                title: `Varios`,
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/festivos/ginger.jpg"),
                title: `Galletas de gengibre`,
              },
              // {
              //   src: require("../../assets/img/project/portfolio-3/festivos/nino_disfraz.jpg"),
              //   title: `Disfraz de halloween`,
              // },
              {
                src: require("../../assets/img/project/portfolio-3/festivos/halloween6.jpg"),
                title: `Halloween`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/festivos/halloween4.jpg"),
                title: `Halloween`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/festivos/ginger_tren.jpg"),
                title: `Tren de gengibre`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/festivos/renos_navidad.jpg"),
                title: `Renos de navidad`,
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/popular/ansel_gretel.jpg"),
                title: `Ansel y Gretel`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/popular/got.jpg"),
                title: `Games of Thrones`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/popular/hadas.jpg"),
                title: `Hadas`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/popular/monstruo_nina.jpg"),
                title: `Monstruo y niña`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/popular/vamos_cama.jpg"),
                title: `Vamos a la cama`,
              },
            ],
          },
          {
            id: 5,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/animales/conejo.jpg"),
                title: `Conejo`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/dinosaurios.jpg"),
                title: `Dinosaurios`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/unicornios.jpg"),
                title: `Unicornios`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/jirafa-leon.jpg"),
                title: `Jirafa y León`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/pandas.jpg"),
                title: `Pandas`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/animales/selva.jpg"),
                title: `Selva`,
              },
            ],
          },
          {
            id: 6,
            content: [
              {
                src: require("../../assets/img/project/portfolio-3/disney/blanca_nieves.jpg"),
                title: `Blanca Nieves`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/encanto.jpg"),
                title: `Encanto`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/sirenita.jpg"),
                title: `Sirenita`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/frozen.jpg"),
                title: `Frozen`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/princesas.jpg"),
                title: `Princesas`,
              },
              {
                src: require("../../assets/img/project/portfolio-3/disney/encanto3.jpg"),
                title: `Encanto familia`,
              },
            ],
          },
        ],
      };
    },
  };
</script>
